import * as React from 'react';

import {
  Breakpoint,

  useDimensions,
} from '@bluecurrent/web-component-lib';

import {
  getClient,
  overlayDrafts,
} from '../src/services/Sanity/sanity.server';
import getSingleton from '../src/services/Sanity/query/getSingleton';

import Page from '../src/config/Page.config';

import PageBuilder from '../src/components/PageBuilder';

import Head from '../src/components/Modules/Head';

export default function AtHome({ data }) {
  const size = useDimensions();

  const render = [
    { component: <PageBuilder data={data} /> },
  ];

  return (
    <>
      <Head
        title={404}
        slug="/404"
      />
      <div
        style={{
          paddingTop: size.width < Breakpoint.xxl ? 0 : 15,
          minHeight: size.width < Breakpoint.lg
            ? Page.MIN_HEIGHT.small_screen
            : Page.MIN_HEIGHT.full_screen,
          height: 'auto',
        }}
      >
        {
          render.map((e, index) => (
            <React.Fragment
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
            >
              {e.component}
            </React.Fragment>
          ))
        }
      </div>
    </>
  );
}

export async function getStaticProps({ preview = false }) {
  // Get Singeltons
  const data = overlayDrafts(await getClient(preview).fetch(getSingleton('404')));

  return {
    props: {
      data,
    },
    revalidate: 300,
  };
}
